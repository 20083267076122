
export enum CentralServerPathParamKind {
    year = "{year}",
    month = "{month}",
    type = "{type}",
}

export enum CentralServerQueryParamKind {
    // Unknown = "unknown",
}

export enum CentralServerEndpointKind {    

    //onboarding
    app_onboarding_interview = "app/onboarding/interview"
}