import React, { createRef, RefObject } from "react";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { AppBonusClaimRankingInfo } from "../lib/drivers/dto/AppBonusClaimRankingInfo";

// Props & State
export type HeadingPart1Props = {
  totalBonusAmount: number;
  commissionPercentage: number;
  invitedFriends: number;
  invitedFriendsKYC: number;
  inviteCode: string;
  onQrCodeButtonClick(): void;
  isLogged: boolean;
  onOpenRankPopupClick(): void;
  ranking: Array<AppBonusClaimRankingInfo>;
};

type HeadingPart1State = {
  rankingPopupState: {
    isOpen: boolean;
  };
  screenWidth: number;
  isCoppiedValue: boolean;
};

export class HeadingPart1 extends React.Component<HeadingPart1Props, HeadingPart1State> {
  textInput: RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);

    this.state = {
      rankingPopupState: {
        isOpen: false
      },
      screenWidth: window.innerWidth,
      isCoppiedValue: false
    };

    this.textInput = createRef();
  }

  // componentDidMount(): void {}

  // componentDidMount = async () => {
  //   const bonusRankings: AppBonusClaimRankingInfo[] = await this.loadBonusClaimRanking();
  //   // const dataCurrIndexItemNew: BonusCommissionIndexDto = await this.loadBonusCommissionIndexCurr();
  // };

  // loadBonusClaimRanking = async (): Promise<AppBonusClaimRankingInfo[]> => {
  //   const result = await PortCentralServer.Repo.client.getBonusClaimRanking();
  //   return result;
  // };

  updateScreenWidth = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  }

  copyToClipboard = async () => {
    if (this.state.screenWidth > 450) {
      const copyText = this.textInput.current;
      if (copyText) {
        try {
          await navigator.clipboard.writeText(copyText.value);
          this.setState({
            ...this.state,
            isCoppiedValue: true
          });
          alert("Copied the text: " + copyText.value);
        } catch (err) {
          alert("Failed to copy text: " + err);
        }
      }
    } else {
      const copyText = `https://bonex.net/register?inviteCode=${this.props.inviteCode}`;

      // Select the text field
      // copyText.select();
      // copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      // document.execCommand("copy");

      // Alert the copied text (optional)
      if (copyText) {
        try {
          await navigator.clipboard.writeText(copyText);
          this.setState({
            ...this.state,
            isCoppiedValue: true
          });
          alert("Copied the text: " + copyText);
        } catch (err) {
          alert("Failed to copy text: " + err);
        }
      }

      alert("Copied the text: " + copyText);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="conatiner heading-container-new wrapper" style={{ margin: "0 0 0px" }}>
          <div className="row large-heading-part" style={{ width: "100%", marginRight: "0px", marginLeft: "0px", backgroundColor: "transparent" }}>
            <div
              className="col-md-8 col-sm-7 main-heading"
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                alignContent: "flex-start",
                alignItems: "flex-start",
                height: "fit-content",
                paddingTop: "58px",
                paddingLeft: "127px",
                justifyContent: "space-evenly"
              }}
            >
              <h2
                className="gradient-text"
                style={{
                  // color: "white",
                  fontWeight: "700",
                  width: "50%",
                  marginBottom: "40px",
                  zIndex: "2"
                }}
              >
                You win. Your traders save. Win - Win
                {/* Welcome to Bonex Partner Program. */}
                {/* Earn up to 10,000 USDT</strong> on your deposits. Enjoy <strong>60%</strong> Commission from your referrals. */}
              </h2>

              <h5
                style={{
                  color: "white",
                  fontWeight: "300",
                  marginBottom: "30px",
                  zIndex: "2",
                  width: "65%"
                }}
              >
                Earn up to <strong className="glow">$250,000</strong> by partnering with Bonex! Earn <strong className="glow">3%</strong> from trading volume as a reward from our Community Relief Fund.
              </h5>

              <button
                onClick={() => window.open("https://www.bonex.net/en_US/login")}
                className="heading-button-new"
                style={{
                  // padding: "4px 18px",
                  // backgroundColor: "purple",
                  // border: "none",
                  // borderRadius: "20px",
                  // color: "white",
                  // zIndex: "2"
                  marginBottom: "50px"
                }}
              >
                {" "}
                Join our Partner program
              </button>

              {/* <img className="btc-planet-mobile" style={{ width: "90%", zIndex: "1" }} src="/images/bitcoin-planet-new.png"></img> */}
            </div>
            <div
              className="col-md-4 col-sm-5"
              style={{
                padding: "0px"
                // display: "flex",
                // alignItems: "end",
                // justifyContent: "flex-end",
                // marginTop: "50px"
              }}
            >
              {/* <img
                className="bonuses-friends-hero friends-icon"
                style={{
                  width: "40%",
                  position: "relative"
                }}
                src="/images/bonuses-friends-hero.png"
              ></img> */}
              {/* These will be added tomorrow */}
              {/* <img style={{position: "relative", top: "50px"}} src="/images/bonuses-hero-usdt-image-1.png"></img> */}
              {/* <img className="btc-planet" style={{ width: "90%", zIndex: "2" }} src="/images/bitcoin-planet-new.png"></img> */}
              <img
                className="purple-planet"
                style={{ zIndex: "2", right: "0%", width: "531px", position: "absolute", opacity: "1.5" }}
                src="/images/purple-planet-last-removebg-preview.png"
                alt="bonex"
              />
              {/* <img className="usdt-icon-1" style={{marginLeft: "-52px"}} src="/images/bonuses-hero-usdt-image-1.png" />
              <img className="usdt-icon-2" style={{marginLeft: "160px"}} src="/images/bonuses-hero-usdt-image-2.png" /> */}
            </div>
            <div className="row you-receive-div" style={{ width: "90%", margin: "auto", marginTop: "80px" }}>
              <div className="col-md-6" style={{}}>
                <h1 className="you-earn-title" style={{ color: "white", fontSize: "58px", fontWeight: "700", marginBottom: "35px" }}>
                  You earn
                </h1>
                <div className="you-earn-texts" style={{ display: "flex" }}>
                  <div className="you-earn-50%">
                    <h3 className="glow glow-percents" style={{ fontSize: "70px", marginBottom: "20px" }}>
                      50%
                    </h3>
                    <h3 style={{ color: "white" }}>Lifetime commission fees!</h3>
                  </div>
                  {/* <div className="you-earn-25%">
                    <h3 className="glow glow-percents" style={{ fontSize: "70px", marginBottom: "20px" }}>
                      +25%
                    </h3>
                    <h3 style={{ color: "white" }}> of your Sub Affiliates' commissions</h3>
                  </div> */}
                </div>
              </div>
              <div className="col-md-6" style={{}}>
                <h1 className="you-earn-title your-affiliates" style={{ color: "white", fontSize: "58px", fontWeight: "700", marginBottom: "35px" }}>
                  Your Affiliates Get
                </h1>
                <div className="you-earn-texts" style={{ display: "flex" }}>
                  <div className="you-earn-discount">
                    <h3 className="glow glow-percents" style={{ fontSize: "70px", marginBottom: "20px" }}>
                      50%
                    </h3>
                    <h3 style={{ color: "white" }}>Discounts on fees for 2 months</h3>
                  </div>
                  <div className="you-earn-lifetime-discount">
                    <h3 className="glow glow-percents" style={{ fontSize: "70px", marginBottom: "20px" }}>
                      25%
                    </h3>
                    <h3 style={{ color: "white" }}> Lifetime discount on fees</h3>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row" style={{ width: "95%", margin: "auto", justifyContent: "center", marginTop: "50px" }}>
              <div className="col-md-3 heading-steps-to-join neon-effect">
                <img className="heading-icon-image" src="/images/heading-icon-1.png"></img>
                <h3 style={{ color: "white" }}>Apply</h3>
                <p style={{ color: "white" }}>Complete the application form below.</p>{" "}
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3 heading-steps-to-join neon-effect" id="yellow-neon-effect">
                <img className="heading-icon-image" src="/images/heading-icon-2.png"></img>
                <h3 style={{ color: "white" }}>Review</h3>
                <p style={{ color: "white" }}>We will carefully review your application.</p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3 heading-steps-to-join neon-effect">
                <img className="heading-icon-image" src="/images/heading-icon-3.png"></img>
                <h3 style={{ color: "white" }}>Active</h3>
                <p style={{ color: "white" }}> Once approved, you will receive a unique referral code to start promoting and earning.</p>
              </div>
            </div> */}
          </div>
          {/* <div className="row" style={{ width: "95%", margin: "auto", justifyContent: "center" }}>
            <div className="col-md-3 heading-steps-to-join neon-effect">
              <img className="heading-icon-image" src="/images/heading-icon-1.png"></img>
              <h3 style={{ color: "white" }}>Apply</h3>
              <p style={{color: "white"}}>Complete the application form below.</p>{" "}
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 heading-steps-to-join neon-effect">
              <img className="heading-icon-image" src="/images/heading-icon-2.png"></img>
              <h3 style={{ color: "white" }}>Review</h3>
              <p style={{color: "white"}}>We will carefully review your application.</p>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 heading-steps-to-join neon-effect">
              <img className="heading-icon-image" src="/images/heading-icon-3.png"></img>
              <h3 style={{ color: "white" }}>Active</h3>
              <p style={{color: "white"}}> Once approved, you will receive a unique referral code to start promoting and earning.</p>
            </div>
          </div> */}
          {/* <div className=""></div> */}
        </div>
      </React.Fragment>
    );
  }
}
