import React from "react";

export type JoinBonexPartProps = {
  isLogged: boolean;
  onQrCodeButtonClick(): void;
};

type JoinBonexPartState = {
  activeIndex: number;
};

export class JoinBonexPart extends React.Component<JoinBonexPartProps, JoinBonexPartState> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeIndex: null
    };
  }

  toggleAnswer = (index: number) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? null : index
    }));
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "transparent",
          height: "200px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <div
          style={{
            margin: "auto",
            textAlign: "center"
          }}
        >
          <h3
            className="join-bonex-footer"
            style={{
              color: "white"
            }}
          >
            {/* Join Bonex Affiliate Program to unlock Up to 60% Commission */}
            Join Bonex Partner Program to Earn up to $250,000!
          </h3>

          <button
            type="button"
            onClick={() => window.open("https://www.bonex.net/en_US/login")}
            // className="heading-button"
            style={{
              padding: "5px 15px",
              border: "none",
              borderRadius: "5px",
              backgroundColor: "#D22AEB",
              marginTop: "30px",
              fontWeight: "600",
              color: "white"
            }}
          >
            {" "}
            Login in Bonex.net
          </button>
        </div>
      </div>
    );
  }
}
