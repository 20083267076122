import axios, { AxiosRequestConfig } from 'axios';
import { AuthCookieManager } from '../AuthCookieManager';
import { ServerEndpointBuilder } from "../core/ServerDriver/ServerEndpointBuilder";
import { UrlParamPair } from '../core/ServerDriver/UrlParamPair';
import { CentralServerEndpointKind, CentralServerPathParamKind } from './CentralServerClientEnums';
import { AppBonusClaimRankingInfo } from './dto/AppBonusClaimRankingInfo';
import { AppBonusClaimRequest } from './dto/AppBonusClaimRequestDto';
import { AppBonusCommissionSurveyRequestDto } from './dto/AppBonusCommissionSurveyRequestDto';
import { AppBonusCommissionSurveyResponseDto } from './dto/AppBonusCommissionSurveyResponseDto';
import { AppBonusUserClaimInfoDto } from './dto/AppBonusUserClaimInfoDto';
import { AppBonusUserInfoDto } from './dto/AppBonusUserInfoDto';
import { BonusClaimDto } from './dto/BonusClaimDto';
import { BonusCommissionIndexDto } from './dto/BonusCommissionIndexDto';
import { IBonusCommissionConfig } from './dto/IBonusCommissionConfig';


type CentralErrorHandler = (error: unknown) => void;

export class CentralServerClient {
    // #region Private fields
    private readonly _endpointBuilder: ServerEndpointBuilder;
    // private _accessToken: string;
    // #endregion

    // #region Properties
    public get endpointBuilder(): ServerEndpointBuilder {
        return this._endpointBuilder;
    }

    // public get accessToken(): string {
    //     return this._accessToken;
    // }

    // public set accessToken(value: string) {
    //     this._accessToken = value;
    // }

    errorHandler?: CentralErrorHandler;
    // #endregion

    // #region Constructor
    constructor() {
        this._endpointBuilder = new ServerEndpointBuilder(process.env.REACT_APP_CENTRAL_BASE_URL);
    }
    // #endregion

    // #region Bonus Commissions


    async saveOnboarding(model: AppBonusCommissionSurveyRequestDto): Promise<AppBonusCommissionSurveyResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.app_onboarding_interview.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: AppBonusCommissionSurveyResponseDto = data as AppBonusCommissionSurveyResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    // #endregion

    // #region Private Functions
    private getHeaders(): AxiosRequestConfig {
        const token = AuthCookieManager.getExchangeToken();

        const headers: AxiosRequestConfig = {};

        if (token) {
            headers.headers = {
                "x-exchange-token": `${token}`
            };
        }

        return headers;
    }
    // #endregion
}