import React, { ChangeEvent } from "react";

export type CalculatorVolumePartProps = {};

type CalculatorVolumePartState = {
  referrals: number;
  trades: number;
  volume: number;
  profit: number;
};

const PARTNER_SHARE_PERCENTAGE = 3;
const PARTNER_SHARE_MAX = 250_000;

export class CalculatorVolumePart extends React.Component<CalculatorVolumePartProps, CalculatorVolumePartState> {
  constructor(props: any) {
    super(props);
    this.state = {
      referrals: 100,
      trades: 20,
      volume: 400,
      profit: 0
    };
  }

  componentDidMount = async () => {
    this.calculateResult();
  };

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const parsedValue = parseFloat(value) || 0;

    let isValid = false;

    switch (name) {
      case "referrals":
        isValid = value === "" || /^[1-9]\d*$/.test(value);
        break;
      case "volume":
        isValid = value === "" || /^[0-9]*\.?[0-9]+$/.test(value);
        break;
      case "trades":
        isValid = value === "" || /^[0-9]*\.?[0-9]+$/.test(value);
        break;
      default:
        break;
    }

    if (isValid) {
      this.setState(
        {
          ...this.state,
          [name]: parsedValue
        },
        () => {
          this.calculateResult(); // Recalculate result after state update
        }
      );
    }
  };

  calculateResult = () => {
    // input values
    const dealVolNum = this.state.volume;
    const referralsNum = this.state.referrals;
    const trades = this.state.trades;
    const base = referralsNum * dealVolNum * trades;

    const share = Math.min(PARTNER_SHARE_MAX, base * (PARTNER_SHARE_PERCENTAGE / 100));

    console.log({
      dealVolNum,
      referralsNum,
      trades,
      base,
      share
    });

    // const result = share * 1; // Example: sum of inputs
    this.setState({ profit: share }); // Update result in state
  };

  onChange = () => {};
  render() {
    return (
      <div style={{ backgroundColor: "transparent", height: "fit-content" }}>
        <div className="container toc-part">
          <div className="row">
            <h1
              className="col-md-10 gradient-text"
              style={{
                margin: "auto",
                marginTop: "140px",
                marginBottom: "80px"
                // color: "white"
              }}
            >
              Calculate your potential earnings
            </h1>
            <div style={{ margin: "auto", marginBottom: "50px" }} className=" row col-md-10">
              <div className="col-md-12" style={{ color: "white", fontSize: "16px" }}>
                <form style={{ maxWidth: "500px", margin: "auto" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flexStart", marginBottom: "15px" }}>
                    <label className="calculator-input-label">Referrals:</label>

                    <input value={this.state.referrals} onChange={this.handleInputChange} name="referrals" className="calculator-input" type="text" />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flexStart", marginBottom: "15px" }}>
                    <label className="calculator-input-label">Avg. Volume:</label>
                    <input value={this.state.volume} onChange={this.handleInputChange} name="volume" className="calculator-input" type="text" />
                  </div>

                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flexStart", marginBottom: "15px" }}>
                    <label className="calculator-input-label">Avg. Trades:</label>
                    <input value={this.state.trades} onChange={this.handleInputChange} name="trades" className="calculator-input" type="text" />
                  </div>

                  <div style={{ textAlign: "center", marginTop: "20px", fontSize: "15px", overflowWrap: "anywhere" }}>
                    <h3 className="gradient-text-small">
                      Estimated Earnings: <strong className="glow-white">${this.state.profit.toLocaleString()}</strong>{" "}
                      {/* <div
                        className="tooltip-container"
                        style={{
                          position: "absolute",
                          // marginTop: "80px",
                          marginLeft: "2px",
                          fontSize: "13px"
                        }}
                      >
                        <p
                          className="data-title-container"
                          style={{
                            color: "white",
                            fontSize: "13px"
                          }}
                        >
                          ⓘ
                        </p>
                        <div className="tooltip-text-dashboard">Calculates are based on {this.state.trades} trades per referral.</div>
                      </div> */}
                    </h3>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
