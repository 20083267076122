import React from "react";

export type PartnerBenefitsPartProps = {};

type PartnerBenefitsPartState = {
  activeIndex: number;
};

export class PartnerBenefitsPart extends React.Component<PartnerBenefitsPartProps, PartnerBenefitsPartState> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeIndex: null
    };
  }

  toggleAnswer = (index: number) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? null : index
    }));
  };

  render() {
    return (
      <div style={{ backgroundColor: "transparent", height: "fit-content" }}>
        <div className="container toc-part">
          <div className="row">
            <h1
              className="col-md-10 gradient-text bonex-partners-benefits-title"
              style={{
                margin: "auto",
                marginTop: "140px",
                marginBottom: "20px"
                // color: "white"
                // textAlign: "right"
              }}
            >
              BONEX Partner Benefits
            </h1>
            <h3 className="bonex-partners-benefits-subtitle" style={{ color: "white", width: "85%", margin: "auto", marginBottom: "80px" }}>
              Join a community of influencers, content creators, and industry leaders who are driving the future of cryptocurrency.
            </h3>
          </div>
          <div className="row" style={{ width: "90%", margin: "auto" }}>
            <div className="col-md-6 benefit-div-wrapper">
              <div className="benefit-div">
                <div className="benefit-icon-wrapper">
                  <img className="benefit-icon" src="/images/benefit-1.png" />
                </div>
                <div className="benefit-text-div">
                  <h3>Earn Up to $250,000</h3>
                  <p>Take advantage of our top-tier earning potential.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 benefit-div-wrapper">
              <div className="benefit-div">
                <div className="benefit-icon-wrapper">
                  <img className="benefit-icon" src="/images/benefit-2.png" />
                </div>
                <div className="benefit-text-div">
                  <h3>Partners</h3>
                  <p>Collaborate with one of the most reliable crypto exchanges in the world.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 benefit-div-wrapper">
              <div className="benefit-div">
                <div className="benefit-icon-wrapper">
                  <img className="benefit-icon" src="/images/benefit-3.png" />
                </div>
                <div className="benefit-text-div">
                  <h3>Transparency</h3>
                  <p>Receive detailed analytics and reports on your promotion efforts.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 benefit-div-wrapper">
              <div className="benefit-div">
                <div className="benefit-icon-wrapper">
                  <img className="benefit-icon" src="/images/benefit-4.png" />
                </div>
                <div className="benefit-text-div">
                  <h3>Reward</h3>
                  <p>Earn 3% from trading volume as a reward from our Community Relief Fund.</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row" style={{ width: "90%", margin: "auto", marginTop: "80px" }}>
            <div className="col-md-6" style={{}}>
              <h1 className="you-earn-title" style={{ color: "white", fontSize: "58px", fontWeight: "700", marginBottom: "35px" }}>You earn:</h1>
              <div className="you-earn-texts" style={{ display: "flex" }}>
                <div className="you-earn-50%">
                  <h3 className="glow" style={{ textAlign: "center", fontSize: "70px", marginBottom: "20px" }}>
                    50%
                  </h3>
                  <h3 style={{ color: "white" }}>Lifetime commissionon fees!</h3>
                </div>
                <div className="you-earn-25%">
                  <h3 className="glow" style={{ textAlign: "center", fontSize: "70px", marginBottom: "20px" }}>
                    +25%
                  </h3>
                  <h3 style={{ color: "white" }}> of your Sub Affiliates' commissions</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6" style={{}}>
              <h1 className="you-earn-title your-affiliates" style={{ color: "white", fontSize: "58px", fontWeight: "700", marginBottom: "35px" }}>
                Your Affiliates Get:
              </h1>
              <div className="you-earn-texts" style={{ display: "flex" }}>
                <div className="you-earn-discount">
                  <h3 className="glow" style={{ textAlign: "center", fontSize: "70px", marginBottom: "20px" }}>
                    50%
                  </h3>
                  <h3 style={{ color: "white" }}>Discounts on fees for 2 months</h3>
                </div>
                <div className="you-earn-lifetime-discount">
                  <h3 className="glow" style={{ textAlign: "center", fontSize: "70px", marginBottom: "20px" }}>
                    25%
                  </h3>
                  <h3 style={{ color: "white" }}> Lifetime discount on fees</h3>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
