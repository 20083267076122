import React from "react";

export type TermsAndConditionsPartProps = {};

type TermsAndConditionsPartState = {
  activeIndex: number;
};

export class TermsAndConditionsPart extends React.Component<TermsAndConditionsPartProps, TermsAndConditionsPartState> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeIndex: null
    };
  }

  toggleAnswer = (index: number) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? null : index
    }));
  };

  render() {
    return (
      <div style={{ backgroundColor: "#060010", height: "fit-content", marginTop: "50px" }}>
        <div className="container toc-part" style={{ paddingTop: "25px" }}>
          <div className="row">
            <h1 className="col-md-10" style={{ margin: "auto", marginTop: "30px", marginBottom: "30px", color: "white" }}>
              Terms Of Conditions
            </h1>
            <div style={{ margin: "auto", marginBottom: "50px" }} className="col-md-10">
              <div className="eligibility" style={{ color: "white", fontSize: "16px" }}>
                <ol>
                  <li className="terms-conditions-li">
                    <strong>KYC Compliance:</strong>
                    To maintain a secure and transparent environment, all participants must complete Identity Verification Level 1 (KYC). This process is mandatory and essential to
                    ensuring the program's integrity by verifying all affiliates. Adherence to KYC safeguards against fraudulent activities, mitigates risks, and strengthens the
                    platform’s trustworthiness. Failure to comply with KYC requirements may result in disqualification from the program and forfeiture of any benefits.
                  </li>
                  <li className="terms-conditions-li">
                    <strong>Zero-Tolerance for Misconduct:</strong>
                    The program enforces a strict zero-tolerance policy toward any form of misconduct or abusive behavior. This includes, but is not limited to, multi-accounting,
                    fraud, or any actions that harm the platform’s reputation or negatively impact other users. Any participant found engaging in such activities will face
                    immediate and permanent termination from the program, with the loss of all earned rewards and privileges. Ethical behavior and transparency are paramount, and
                    violations will be addressed promptly and decisively.
                  </li>
                  <li className="terms-conditions-li">
                    <strong>Exclusive 3% Reward on Trading Volume:</strong>
                    Affiliates who refer 100 or more active users are eligible for an exclusive 3% reward on trading volume, applicable only to open trades. This reward, funded by
                    the Community Relief Fund, acknowledges the significant contributions of these partners to the platform's growth. To ensure fairness, a thorough monthly
                    evaluation is conducted to prevent fraudulent or manipulative activities. Only partners who meet high ethical standards and demonstrate genuine engagement
                    qualify for this reward.
                  </li>
                  <li className="terms-conditions-li">
                    <strong>Community Relief Fund:</strong>
                    The Community Relief Fund is a dedicated reserve established to support the platform’s community-driven initiatives and ensure stability within the affiliate
                    program. A portion of the trading fees generated on the platform is allocated to this fund. Its primary purpose is to provide exclusive rewards, such as the 3%
                    trading volume reward, to top-performing affiliates who contribute to the platform’s growth and user engagement. The fund also serves as a financial buffer to
                    assist the community in times of crisis or market volatility, ensuring that the platform remains sustainable and resilient. Allocation of rewards from the fund
                    is closely monitored to maintain fairness and transparency, with a focus on rewarding affiliates who adhere to the program’s ethical and compliance standards.
                  </li>
                  <li className="terms-conditions-li">
                    <strong>Ongoing Monitoring and Evaluation:</strong>
                    In addition to monthly evaluations, the platform reserves the right to perform random audits and inspections on all partners and their referred users. This
                    ongoing monitoring ensures strict adherence to the program's guidelines, preserves accountability, and protects the program’s integrity. Only compliant and
                    qualified participants will benefit from the rewards and opportunities offered.
                  </li>
                  <li className="terms-conditions-li">
                    <strong>Confidentiality and Data Protection:</strong>
                    Participants must adhere to strict confidentiality standards. Any sensitive information related to the program, platform, or other participants must be treated
                    with care. Unauthorized sharing or misuse of confidential information will lead to immediate expulsion from the program. The platform is committed to protecting
                    personal data and ensuring it is securely stored and processed in compliance with data protection laws.
                  </li>
                  <li className="terms-conditions-li">
                    <strong>Program Amendments:</strong>
                    The platform reserves the right to modify the terms and conditions of the affiliate program as needed to reflect platform updates, regulatory changes, or market
                    conditions. Participants will be notified in advance of any significant changes, and continued participation will signify acceptance of the revised terms. It is
                    the responsibility of participants to regularly review these terms to stay informed of updates.
                  </li>
                  <li className="terms-conditions-li">
                    <strong>Dispute Resolution:</strong>
                    In the event of disputes arising from the program, participants are encouraged to utilize the platform’s dispute resolution procedures for fair and impartial
                    outcomes. Unresolved disputes may be subject to arbitration, in accordance with the platform’s dispute resolution policy, which is binding on all participants.
                  </li>
                  <li className="terms-conditions-li">
                    <strong>Termination of Participation:</strong>
                    The platform retains the right to terminate any participant’s involvement in the program at its discretion, particularly in cases of non-compliance with terms
                    or actions detrimental to the platform or its users. Termination will result in the forfeiture of rewards, benefits, and privileges. Participants may
                    voluntarily leave the program, provided they meet all outstanding obligations.
                  </li>
                  <li className="terms-conditions-li">
                    <strong>Legal Compliance:</strong>
                    All participants are required to ensure that their activities within the program comply with applicable laws and regulations, including financial regulations,
                    anti-money laundering (AML) laws, and data protection rules. The platform reserves the right to take legal action against any participant found in violation of
                    these laws.
                  </li>
                </ol>
              </div>
              {/* <div className="eligibility" style={{ color: "white" }}>
                <h2 style={{ color: "white" }}>Eligibility</h2>
                <p style={{ color: "white" }}>
                  Only registered users who have completed KYC (Know Your Customer) verification can participate in the Trading Boost and other promotions.
                </p>

                <h3 style={{ color: "white" }}>Trading Boost</h3>
                <ul style={{ color: "white" }}>
                  <li className="terms-conditions-li">The Trading Boost applies only to new deposits.</li>
                  <li className="terms-conditions-li">Bonuses must be used for trading purposes only.</li>
                  <li className="terms-conditions-li">The bonus will be deposited after holding the deposited sum in the account for at least 7 days.</li>
                  <li className="terms-conditions-li">You must make at least one trade within 7 days of depositing.</li>
                  <li>Your deposit and trading volume must be in the futures wallet.</li>
                  <li>The boost is valid only for your first deposit.</li>
                  <li>Message us to claim your trading boost.</li>
                </ul>

                <h3 style={{ color: "white" }}>KYC Bonus</h3>
                <ul style={{ color: "white" }}>
                  <li>Register through a partner's referral link and complete KYC verification to receive a $20 trading bonus.</li>
                  <li>This bonus is a one-time reward.</li>
                </ul>

                <h3 style={{ color: "white" }}>General Terms</h3>
                <ul style={{ color: "white" }}>
                  <li>All promotions and bonuses are available on a first-come, first-served basis.</li>
                  <li>Users must comply with Identity Verification Level 1 (KYC Compliance).</li>
                  <li>The KYC bonus remains valid in the futures wallet for 30 days following its crediting</li>
                  <li>Engaging in abusive behaviors such as multi-accounting will result in immediate termination from the program.</li>
                  <li>For comprehensive rules and benefits, refer to the Latest Promotions, Bonus, and Coupons FAQ on the BoneX Margin platform.</li>
                  <li>Trading Vouchers for Spot are not compatible with a Unified Trading Account.</li>
                  <li>Users from specific regions may face restrictions.</li>
                  <li>Progress updates are made every four hours.</li>
                  <li>Bonus Withdrawal: Profit earned from a bonus can be withdrawn one month after the bonus is received.</li>
                  <li>Accounts may be locked and funds frozen if suspicious activity is detected to ensure the safety and integrity of our platform.</li>
                </ul>

                <h3 style={{ color: "white" }}>Bonex Affiliate Program</h3>
                <ul style={{ color: "white" }}>
                  <li>Both you and your affiliates must complete the KYC verification process.</li>
                  <li>Earn a 60% commission on every transaction made by your affiliates.</li>
                  <li>There is no cap on how much you can earn.</li>
                  <li>Commissions and performance metrics can be tracked using the dashboard, which updates every two hours.</li>
                  <li>Access exclusive promotions and bonuses tailored for top affiliates.</li>
                  <li>For any questions or further details, please contact our support team.</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
