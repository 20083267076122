import React, { ChangeEvent, FormEvent } from "react";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import { AppBonusCommissionSurveyRequestDto } from "../lib/drivers/dto/AppBonusCommissionSurveyRequestDto";
import { AppBonusCommissionSurveyQuestionPairDto } from "../lib/drivers/dto/AppBonusCommissionSurveyQuestionPairDto";
import { Direction } from "react-toastify/dist/utils";

export type FormPartNewProps = {
  saveOnbording(model: AppBonusCommissionSurveyRequestDto): void;
};

class Question {
  constructor({ name, title, question, inputValue, inputType, placeholder, className, required, initialValue, values }: any) {
    this.name = name;
    this.title = title;
    this.question = question || title;
    this.initialValue = inputValue;

    if (this.inputType === "checkbox") {
      this.values = values;
    }
  }

  public name: string;
  public title: string;
  public question: string;
  public inputValue: string;
  public inputType: string;
  public placeholder: string;
  public className: string;
  public required: boolean;
  public initialValue: any;
  public values: any | undefined;
}

const sections = {
  section1: {
    index: 1,
    title: "test",
    questions: [
      new Question({
        name: "fullName",
        title: "Full Name",
        question: "What's your full name",
        initialValue: "",
        inputType: "text",
        placeholde: "Your full name",
        className: "input-standart",
        required: true
      })
    ]
  },
  section2: {}
};
interface FormPartNewState {
  activeIndex: number;
  section1Open: boolean;
  section2Open: boolean;
  section3Open: boolean;
  section4Open: boolean;
  formQuestionAnswerPairs: { [key: string]: string | string[] };
  isFormSubmitted: boolean;
}

// type FormPartNewState = {
//   activeIndex: number;
//   section1Open: boolean;
//   section2Open: boolean;
//   section3Open: boolean;
// };

export class FormPartNew extends React.Component<FormPartNewProps, FormPartNewState> {
  // constructor(props: any) {
  //   super(props);
  //   this.state = {};
  // }

  // componentDidMount(): void {}

  // render() {
  //   return (
  //     <React.Fragment>
  //       <div className="row how-to-invite" style={{ width: "100%", margin: "auto", height: "fit-content", padding: "50px 0px" }}>
  //         <h2 style={{ color: "white", width: "89%", margin: "auto", marginBottom: "60px" }}>FAQ(s)</h2>

  //       </div>
  //     </React.Fragment>
  //   );
  // }
  constructor(props: any) {
    super(props);
    this.state = {
      activeIndex: null,
      section1Open: true,
      section2Open: false,
      section3Open: false,
      section4Open: false,
      formQuestionAnswerPairs: {
        promotionMethods: []
      },
      isFormSubmitted: false
    };
  }

  toggleSection = (section: keyof FormPartNewState) => {
    this.setState((prevState) => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  toggleAnswer = (index: number) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? null : index
    }));
  };

  // handleSubmit = (event: FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  // };
  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formKeyValuePairs = this.state.formQuestionAnswerPairs;
    const selectedAnswers = Object.keys(formKeyValuePairs).map((fieldName) => ({
      question: fieldName,
      answers: Array.isArray(formKeyValuePairs[fieldName]) ? formKeyValuePairs[fieldName] : [formKeyValuePairs[fieldName]]
    })) as AppBonusCommissionSurveyQuestionPairDto[];

    const request = { items: selectedAnswers };
    console.log("request", request);

    this.props.saveOnbording(request);
    this.setState({
      ...this.state,
      isFormSubmitted: true
    });
  };

  handleChange = (event: ChangeEvent<HTMLFormElement>) => {
    const { name, value } = event.target;

    if (name === "promotionMethods") {
      const selectedOptions = this.state.formQuestionAnswerPairs?.promotionMethods as string[];

      if (selectedOptions?.includes(value)) {
        this.setState({
          formQuestionAnswerPairs: {
            ...this.state.formQuestionAnswerPairs,
            [name]: selectedOptions?.filter((option) => option !== value)
          }
        });
      } else {
        this.setState({
          formQuestionAnswerPairs: {
            ...this.state.formQuestionAnswerPairs,
            [name]: [...selectedOptions, value]
          }
        });
      }

      return;
    }

    this.setState({
      formQuestionAnswerPairs: {
        ...this.state.formQuestionAnswerPairs,
        [name]: value
      }
    });
  };

  render() {
    return (
      <div
        style={{
          // backgroundColor: "#060010",
          height: "fit-content"
          // marginTop: "100px"
        }}
      >
        <div className="container">
          <div className="row">
            <h1
              className="col-md-10 gradient-text bonex-partners-benefits-title"
              style={{
                margin: "auto",
                marginTop: "140px",
                marginBottom: "20px"
                // color: "white"
                // textAlign: "right"
              }}
            >
              Join our affiliate program
            </h1>
          </div>
          <div className="row" style={{ width: "95%", margin: "auto", justifyContent: "center", marginTop: "90px" }}>
            <div className="col-md-3 heading-steps-to-join neon-effect">
              <img className="heading-icon-image" src="/images/heading-icon-1.png"></img>
              <h3 style={{ color: "white" }}>Apply</h3>
              <p style={{ color: "white" }}>Complete the application form below.</p>{" "}
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 heading-steps-to-join neon-effect" id="yellow-neon-effect">
              <img className="heading-icon-image" src="/images/heading-icon-2.png"></img>
              <h3 style={{ color: "white" }}>Review</h3>
              <p style={{ color: "white" }}>We will carefully review your application.</p>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 heading-steps-to-join neon-effect">
              <img className="heading-icon-image" src="/images/heading-icon-3.png"></img>
              <h3 style={{ color: "white" }}>Active</h3>
              <p style={{ color: "white" }}> Once approved, you will receive a unique referral code to start promoting and earning.</p>
            </div>
          </div>
          <div className="row">
            {this.state.isFormSubmitted ? (
              <div style={{ width: "80%", margin: "auto", marginTop: "30px", textAlign: "center", border: "1px solid white", borderRadius: "5px", padding: "20px" }}>
                <h3 style={{ color: "white" }}>Thank you for your answers!</h3>
              </div>
            ) : (
              <form className="form-container" style={{ marginTop: "150px" }} onSubmit={this.handleSubmit} onChange={this.handleChange}>
                {/* {Object.keys(sections).map((sectionKey) => {
                // @ts-ignore
                const section = sections[sectionKey];
                const questions = section.questions;

                return (
                  <div className="section">
                    <button type="button" className="section-button" onClick={() => this.toggleSection(section.index)}>
                      {section.title}
                    </button>

                    <div className={`section-content ${this.state[section.index] ? "open" : ""}`}>
                      {questions.map((question) => {
                        //if checkbox
                        return (
                          <div className="input-label-wrapper">
                            <label className="input-label">{question.title}</label>
                            <input name="firstName" className="input-field" type={question.inputType} placeholder="Your First Name" required />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
                return null;
              })} */}
                {/* Section 1 */}
                <div className="section">
                  <button type="button" className="section-button" onClick={() => this.toggleSection("section1Open")}>
                    Section 1: Basic Information
                  </button>
                  <div className={`section-content ${this.state.section1Open ? "open" : ""}`}>
                    <div className="input-label-wrapper">
                      <label className="input-label">First Name: (Required) </label>
                      <input name="firstName" className="input-field" type="text" placeholder="Your First Name" required />
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">Last Name: (Optional) </label>
                      <input name="lastName" className="input-field" type="text" placeholder="Your Last Name" />
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">Email: (Required) </label>
                      <input name="email" className="input-field" type="email" placeholder="example@gmail.com" required />
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">Your 8-Digit Bonex UID: (Required)</label>
                      <input name="bonexUid" className="input-field" type="text" placeholder="12345678" required />
                    </div>
                  </div>
                </div>

                {/* Section 2 */}
                <div className="section">
                  <button type="button" className="section-button" onClick={() => this.toggleSection("section2Open")}>
                    Section 2: Tell Us About Yourself
                  </button>
                  <div className={`section-content ${this.state.section2Open ? "open" : ""}`}>
                    <div className="input-label-wrapper">
                      <label className="input-label">What type of partner are you? (Select from the dropdown) </label>
                      <select name="partnerType" className="input-field">
                        <option></option>
                        <option>Individual Influencer</option>
                        <option>Crypto Community Leader</option>
                        <option>Content Creator (YouTube, Blog, etc.)</option>
                        <option>Website Owner</option>
                        <option>Digital Marketer</option>
                        {/* <option>Other (Please specify)</option> */}
                      </select>
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">How do you plan to promote Bonex? (Select all that apply) </label>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                          <input type="checkbox" id="Twitter" name="promotionMethods" value="Twitter" />
                          <label className="input-label" htmlFor="Twitter">
                            {" "}
                            Twitter
                          </label>
                        </div>
                        <div>
                          <input type="checkbox" id="Telegram" name="promotionMethods" value="Telegram" />
                          <label className="input-label" htmlFor="Telegram">
                            {" "}
                            Telegram
                          </label>
                        </div>
                        <div>
                          <input type="checkbox" id="YouTube" name="promotionMethods" value="YouTube" />
                          <label className="input-label" htmlFor="YouTube">
                            {" "}
                            YouTube
                          </label>
                        </div>
                        <div>
                          <input type="checkbox" id="Discord" name="promotionMethods" value="Discord" />
                          <label className="input-label" htmlFor="Discord">
                            {" "}
                            Discord
                          </label>
                        </div>
                        <div>
                          <input type="checkbox" id="Instagram" name="promotionMethods" value="Instagram" />
                          <label className="input-label" htmlFor="Instagram">
                            {" "}
                            Instagram
                          </label>
                        </div>
                        <div>
                          <input type="checkbox" id="Blog" name="promotionMethods" value="Blog" />
                          <label className="input-label" htmlFor="Blog">
                            {" "}
                            Blog
                          </label>
                        </div>
                        <div>
                          <input type="checkbox" id="Website" name="promotionMethods" value="Website" />
                          <label className="input-label" htmlFor="Website">
                            {" "}
                            Website
                          </label>
                        </div>
                        <div>
                          <input type="checkbox" id="SearchEngineOptimisation" name="promotionMethods" value="Search Engine Optimisation" />
                          <label className="input-label" htmlFor="SsearchEngineOptimisation">
                            {" "}
                            Search Engine Marketing
                          </label>
                        </div>
                        <div>
                          <input type="checkbox" id="AffiliateNetworks" name="promotionMethods" value="Affiliate Networks" />
                          <label className="input-label" htmlFor="AffiliateNetworks">
                            {" "}
                            Affiliate Networks
                          </label>
                        </div>
                        <div>
                          <input type="checkbox" id="Forums" name="promotionMethods" value="Forums" />
                          <label className="input-label" htmlFor="Forums">
                            {" "}
                            Forums
                          </label>
                        </div>
                        {/* <div>
                          <input type="checkbox" id="other" name="promotionMethods" value="Other" />
                          <label className="input-label" htmlFor="Others">
                            {" "}
                            Other (Pleace specify)
                          </label>
                        </div> */}
                      </div>
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">How many people do you expect to bring to Bonex? (Select from the dropdown) </label>
                      <select name="expectedPeople" className="input-field">
                        <option>100</option>
                        <option>100 - 1,000</option>
                        <option>1,000 - 5,000</option>
                        <option>5,000</option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* Section 3 */}
                <div className="section">
                  <button type="button" className="section-button" onClick={() => this.toggleSection("section3Open")}>
                    Section 3: Social Media & Web Presence
                  </button>
                  <div className={`section-content ${this.state.section3Open ? "open" : ""}`}>
                    <div className="input-label-wrapper">
                      <label className="input-label">Twitter: </label>
                      <input name="userTwitter" className="input-field" type="text" placeholder="" />
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">Telegram </label>
                      <input name="userTelegram" className="input-field" type="text" placeholder="" />
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">Youtube </label>
                      <input name="userYouTube" className="input-field" type="text" placeholder="" />
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">Discord</label>
                      <input name="userDiscord" className="input-field" type="text" placeholder="" />
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">Instagram</label>
                      <input name="userInstagram" className="input-field" type="text" placeholder="" />
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">Website/Blog</label>
                      <input name="userWebsiteBlog" className="input-field" type="text" placeholder="" />
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">TikTok</label>
                      <input name="userTikTok" className="input-field" type="text" placeholder="" />
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">LinkedIn</label>
                      <input name="userLinkedin" className="input-field" type="text" placeholder="" />
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">Facebook</label>
                      <input name="userFacebook" className="input-field" type="text" placeholder="" />
                    </div>
                    {/* <div className="input-label-wrapper">
                      <label className="input-label">Others</label>
                      <input name="userOthers" className="input-field" type="text" placeholder="" />
                    </div> */}
                  </div>
                </div>

                {/* Section 4 */}
                <div className="section">
                  <button type="button" className="section-button" onClick={() => this.toggleSection("section4Open")}>
                    Section 4: Additional Information
                  </button>
                  <div className={`section-content ${this.state.section4Open ? "open" : ""}`}>
                    <div className="input-label-wrapper">
                      <label className="input-label">Place of Residence? (Select from the dropdown) </label>
                      <select name="residencePlace" className="input-field">
                        <option>100</option>
                        <option>100 - 1,000</option>
                        <option>1,000 - 5,000</option>
                        <option>5,000</option>
                      </select>
                    </div>
                    {/* <div className="input-label-wrapper">
                    <label className="input-label">Your 8-Digit Bonex UID: (Required)</label>
                    <input name="bonexUid" className="input-field" type="text" placeholder="" required />
                  </div> */}
                    <div className="input-label-wrapper">
                      <label className="input-label">How did you hear about the Bonex Affiliate Program? (Select from the dropdown) </label>
                      <select name="whereDidYouLearnAboutUs" className="input-field">
                        <option>Social Media</option>
                        <option>Friend/Referral</option>
                        <option>Online search</option>
                        <option>Crypto Forums</option>
                        {/* <option>Other (Please specify)</option> */}
                      </select>
                    </div>
                    <div className="input-label-wrapper">
                      <label className="input-label">Referral Code: (Optional)</label>
                      <input name="refferalCode" className="input-field" type="text" placeholder="" />
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <button className="submit-form-button" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      // <div
      //   style={{
      //     backgroundColor: "#060010",
      //     height: "fit-content"
      //   }}
      // >
      //   <div className="container">
      //     <div className="row">
      //       <h1
      //         className="col-md-10"
      //         style={{
      //           margin: "auto",
      //           marginTop: "70px",
      //           marginBottom: "30px",
      //           color: "white"
      //         }}
      //       >
      //         FAQ(s)
      //       </h1>
      //       <div style={{ marginBottom: "50px" }}>
      //         {faqs.map((faq, index) => (
      //           <div
      //             key={index}
      //             className="col-md-10"
      //             style={{
      //               margin: "auto",
      //               borderBottom: "1px solid #ffffff5c"
      //             }}
      //           >
      //             <div
      //               className="row"
      //               onClick={() => this.toggleAnswer(index)}
      //               style={{
      //                 cursor: "pointer",
      //                 fontWeight: "bold",
      //                 margin: "10px 0"
      //               }}
      //             >
      //               <h5
      //                 className="col-sm-11"
      //                 style={{
      //                   color: "white",
      //                   margin: "15px 0px",
      //                   fontWeight: "400",
      //                   fontFamily: "sans-serif"
      //                 }}
      //               >
      //                 {faq.question}
      //               </h5>
      //               <h5
      //                 className="col-sm-1"
      //                 style={{
      //                   color: "#ffffff5c",
      //                   display: "flex",
      //                   alignItems: "center",
      //                   fontWeight: "400"
      //                 }}
      //               >
      //                 ↓
      //               </h5>
      //             </div>
      //             {this.state.activeIndex === index && (
      //               <div style={{ margin: "10px 0 20px 10px" }}>
      //                 {Array.isArray(faq.answer) ? (
      //                   <ul style={{ whiteSpace: "no-wrap" }}>
      //                     {faq.answer.map((indexAnswer) => (
      //                       <li>
      //                         <p
      //                           style={{
      //                             color: "white",
      //                             fontWeight: "400"
      //                           }}
      //                         >
      //                           {indexAnswer}
      //                         </p>
      //                       </li>
      //                     ))}
      //                   </ul>
      //                 ) : (
      //                   <p
      //                     style={{
      //                       color: "white",
      //                       fontWeight: "400"
      //                     }}
      //                   >
      //                     {faq.answer}
      //                   </p>
      //                 )}
      //               </div>
      //             )}
      //           </div>
      //         ))}
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
